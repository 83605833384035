@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --inselo-black: #000000;
  --inselo-white: #FFFFFF;
  --inselo-gray: #2B2B2B;
  --inselo-light-gray: #F2F2F2;
  --inselo-red: #FF5043;
  --inselo-light-red: #FF968E;
  --inselo-yellow: #FFCD00;
  --inselo-light-yellow: #F0D566;
  --inselo-blue: #41B6E6;
  --inselo-light-blue: #8DD3F0;
  --inselo-green: #A4D65E;
  --inselo-light-green: #C2DD9A;
}

body {
  min-width: screen;
  min-height: screen;
  color: white;
  font-family: 'Roboto', sans-serif;
}

input[type='checkbox']{
  accent-color: var(--inselo-blue);
}

input[type='range']{
  accent-color: var(--inselo-blue);
}

.bg-inselo-red {
  background-color: var(--inselo-red);
}

.bg-inselo-light-red {
  background-color: var(--inselo-light-red);
}

.inselo-red {
  color: var(--inselo-red);
}

.inselo-light-red {
  color: var(--inselo-light-red);
}

.bg-inselo-blue {
  background-color: var(--inselo-blue);
}

.inselo-blue {
  color: var(--inselo-blue);
}

.bg-inselo-light-blue {
  background-color: var(--inselo-light-blue);
}

.inselo-light-blue {
  color: var(--inselo-light-blue);
}

.inselo-green {
  color: var(--inselo-green);
}

.bg-inselo-green {
  background-color: var(--inselo-green);
}

.bg-inselo-yellow {
  background-color: var(--inselo-yellow);
}

.iheader {
  background-color: var(--inselo-light-green);
}

.ibutton {
  background-color: var(--inselo-gray);
}

.isidebar {
  background-color: var(--inselo-light-gray);
}

.igray {
  background-color: var(--inselo-gray);
}

.iwgray {
  background-color: var(--inselo-light-blue);
}

.iconfirm {
  background-color: var(--inselo-green);
}

.icancel {
  background-color: var(--inselo-red);
}

.split {
  display: flex;
  flex-direction: row;
}

.gutter {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-horizontal {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
  cursor: col-resize;
}

.addProductField {
  height: 2rem;
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0.25rem;
  color: rgb(0 0 0);
}

.addProductReadonlyField {
  height: 2rem;
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0.25rem;
  border-width: 2px;
  background-color: transparent;
  color: rgb(255 255 255)
}


.editBoxField {
  height: 1.8rem;
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0.375rem;
  color: rgb(0 0 0);
}

.editBoxReadonlyField {
  height: 1.8rem;
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0.375rem;
  border-width: 2px;
  background-color: transparent;
  color: rgb(255 255 255)
}

.selectBoxField {
  height: 1.75rem;
  width: 100%;
  padding-left: 0.1rem;
  padding-right: 0.5rem;
  border-radius: 0.375rem;
  color: rgb(0 0 0);
}

.selectBoxReadonlyField {
  height: 1.75rem;
  width: 100%;
  padding-left: 0.1rem;
  padding-right: 0.5rem;
  border-radius: 0.375rem;
  border-width: 2px;
  background-color: transparent;
  color: rgb(255 255 255)
}

.tp-button-cancel {
  color: var(--inselo-red);
  border-color: var(--inselo-red);
  min-width: 5rem;
  border-width: 2px;
  border-radius: 0.375rem;
  padding: 0.25rem;
}

.tp-button-confirm {
  color: var(--inselo-green);
  border-color: var(--inselo-green);
  min-width: 5rem;
  border-width: 2px;
  border-radius: 0.375rem;
  padding: 0.25rem;
}



.tp-flex-button-cancel {
  color: var(--inselo-red);
  border-color: var(--inselo-red);
  border-width: 2px;
  border-radius: 0.375rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.tp-flex-button-confirm {
  color: var(--inselo-green);
  border-color: var(--inselo-green);
  border-width: 2px;
  border-radius: 0.375rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}